import React, { useEffect, useState } from 'react';
import { Button, Col } from 'antd';
import { findIndex, sumBy, isEmpty, find } from 'lodash';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import axios from 'axios';
import axiosInstance from 'Src/common/utilities/axios_util';
import { PAGE_UGC_API, PAGE_UGC_REACTION_API } from 'Src/alumniGiving/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faHeart, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { faGrinBeam } from '@fortawesome/pro-duotone-svg-icons';
import Item from './item';
import AnimationBlock from './animationBlock';
import './style.scss';

let pageNum = 1;
let url = '';
let alreadyMadeRequest = false;

const instance = axios.create({
  baseURL: '/api/v1',
  timeout: 3000,
  headers: {
    'Content-type': 'application/json',
    'X-CSRFToken': window.csrfToken,
  },
});

// npm fas icons mapped to their classnames
const reactionIcons = {
  'fad fa-grin-beam': faGrinBeam,
  'fas fa-heart': faHeart,
  'fas fa-thumbs-up': faThumbsUp,
};

function HonorWall() {
  const [settings] = SettingsConsumer();
  const [messages, setMessages] = useState([]);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [next, setNext] = useState(null);
  const [width, setWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [selectedHonorWall, setSelectedHonorWall] = useState({});

  function updateReactions(selectedWall, reaction) {
    instance({
      method: 'post',
      url: PAGE_UGC_REACTION_API.replace('{}', settings.page.id),
      data: { ugc_id: selectedWall, kind: reaction },
    });
  }

  function getUGC(urlL) {
    return axiosInstance({
      method: 'get',
      url: urlL,
    });
  }

  useEffect(() => {
    url = PAGE_UGC_API.replace('{}', settings.page.id);
    getUGC(url).then((response) => {
      setMessages(response.data.results);
      setNext(response.data.next);
      if (!isEmpty(document.getElementsByClassName('honor-wall-container'))) {
        setWidth(document.getElementsByClassName('honor-wall-container')[0].clientWidth);
        setScrollWidth(document.getElementsByClassName('honor-wall-wrapper')[0].scrollWidth);
      }
    });
  }, []);

  function selectEmoji(selectedWall, reaction) {
    const index = findIndex(messages, (val) => val.id === selectedWall);
    if (index > -1) {
      const selectedMessage = messages[index];
      const innerIndex = findIndex(selectedMessage.reactions, (val) => val.kind === reaction);
      const totalCount = sumBy(selectedMessage.reactions, (val) => (val.is_self ? 1 : 0));
      if (totalCount === 0) {
        if (innerIndex === -1) {
          selectedMessage.reactions.push({
            kind: reaction,
            count: 1,
            is_self: true,
          });
        } else {
          selectedMessage.reactions[innerIndex].count += 1;
          selectedMessage.reactions[innerIndex].is_self = true;
        }
        messages[index] = selectedMessage;
        messages[index].total_reactions += 1;
        setMessages(messages.slice());
        updateReactions(selectedWall, reaction);
      }
      const r = find(selectedMessage.reactions, (val) => val.kind === reaction);
      if (!isEmpty(r) && r.is_self) {
        attachAnimationNode(selectedWall, reaction);
      }
    }
  }

  function getReaction(reactions, reaction) {
    const index = findIndex(reactions, (val) => val.kind === reaction);
    if (index > -1) {
      return reactions[index];
    }
    return {};
  }

  function getCount(reactions, reaction) {
    return getReaction(reactions, reaction).count || 0;
  }

  // function getSelfLike(reactions, reaction) {
  //     return getReaction(reactions, reaction).is_self || false
  // }

  function scrollWall(e) {
    setScrollLeft(e.target.scrollLeft);
    if (next && !alreadyMadeRequest && e.target.scrollLeft + width + 100 > e.target.scrollWidth) {
      alreadyMadeRequest = true;
      url = PAGE_UGC_API.replace('{}', settings.page.id).concat(`?page=${++pageNum}`);
      getUGC(url).then((response) => {
        setMessages(messages.concat(response.data.results));
        setNext(response.data.next);
        alreadyMadeRequest = false;
        setWidth(document.getElementsByClassName('honor-wall-container')[0].clientWidth);
        setScrollWidth(document.getElementsByClassName('honor-wall-wrapper')[0].scrollWidth);
      });
    }
  }

  function scrollWallRight() {
    document.getElementsByClassName('honor-wall-wrapper')[0].scrollBy({
      left: 300,
      top: 0,
      behavior: 'smooth',
    });
  }

  function scrollWallLeft() {
    document.getElementsByClassName('honor-wall-wrapper')[0].scrollBy({
      left: -300,
      top: 0,
      behavior: 'smooth',
    });
  }

  function attachAnimationNode(id, reaction) {
    let data = [];
    if (selectedHonorWall[id]) {
      data = selectedHonorWall[id];
    }
    data.push(<AnimationBlock reaction={reactionIcons[reaction]} />);
    selectedHonorWall[id] = data.slice();
    setSelectedHonorWall({ ...selectedHonorWall });
  }

  return (
    <If condition={!isEmpty(messages)}>
      <Col span={24}>
        <div className="honor-wall-container">
          <div className="mock-honor-wall" style={{ width }}>
            <If condition={scrollLeft > 0}>
              <Button className="scroll-left" onClick={() => scrollWallLeft()}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            </If>
            <If condition={scrollLeft + width < scrollWidth}>
              <Button className="scroll-right" onClick={() => scrollWallRight()}>
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </If>
          </div>
          <div className="honor-wall-wrapper mt16" onScroll={scrollWall}>
            {messages.map((message) => (
              <Item
                message={message}
                selectedHonorWall={selectedHonorWall}
                selectEmoji={selectEmoji}
                getCount={getCount}
                key={message.id}
                reactionIcons={reactionIcons}
              />
            ))}
          </div>
        </div>
      </Col>
    </If>
  );
}

export default HonorWall;

/* eslint-disable no-unused-vars */
/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from 'react';
import { List } from 'antd';
import { TOP_RESULTS_LEADERBOARD_API } from 'Src/alumniGiving/endpoints';
import ThemeXModal from 'Src/common/components/themeXModal';
import InfiniteScroll from 'react-infinite-scroller';
import axiosInstance from 'Src/common/utilities/axios_util';
import { pollLeaderboardData } from 'Src/alumniGiving/common/utils/helper';
import { SettingsConsumer } from 'Src/alumniGiving/context/settings';
import { commaSeparatedAmountAndCurrency, formatter } from 'Src/common/utilities/data_util';
import { TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT } from 'Src/alumniGiving/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faSeedling } from '@fortawesome/pro-light-svg-icons';
import { func, shape } from 'prop-types';
import './giftModal.scss';
import GiftModalLoadingCard from '../leaderboardLoaders/giftModalLoader';

const initialDelay = 2000;
function GiftModal({ leaderboard, item, closeModal }) {
  const [giftData, setGiftData] = useState({ results: [] });
  const [settings] = SettingsConsumer();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [nextPageNumber, setNextPageNumber] = useState(0);
  const nextPageNumberRef = useRef(nextPageNumber);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 10;

  function fetchContributors() {
    const queryParams = `dimension=${encodeURIComponent(item.label)}&&dimension_type=${
      item.groupable ? 'group' : 'item'
    }&&page=${nextPageNumberRef.current}&&page_size=${pageSize}`;
    return axiosInstance({
      method: 'get',
      url: TOP_RESULTS_LEADERBOARD_API.replace('{}', settings.page.id)
        .replace('<lb_id>', leaderboard.id)
        .concat(`?${queryParams}`),
    });
  }

  const getInitialContributersData = async (page) => {
    if (nextPageNumberRef.current !== page) {
      setIsLoading(false);
      return false;
    }
    try {
      const response = await fetchContributors();
      setGiftData(response.data);
      setHasMore(response.data?.results?.length < response.data?.count_gifts);
      if (response.data) setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setNextPageNumber(1);
    nextPageNumberRef.current = 1;
    pollLeaderboardData(async () => getInitialContributersData(1), initialDelay);
    return () => {
      nextPageNumberRef.current = 0; // Dismounting the poll function when component dismounts
    };
  }, []);

  const getPaginatedContributersData = async (currentGiftData, page) => {
    if (nextPageNumberRef.current !== page) {
      setIsLoadingMore(false);
      return false;
    }
    try {
      const response = await fetchContributors();
      if (nextPageNumberRef.current === page) {
        const results = response.data?.results || [];
        const updatedGiftData = {
          count_gifts: response.data?.count_gifts,
          total_amount: response.data?.total_amount,
          results: [...currentGiftData.results, ...results],
        };
        setGiftData(updatedGiftData);
        setHasMore(updatedGiftData?.results?.length < response.data?.count_gifts);
        if (response.data) setIsLoadingMore(false);
      }
      return response;
    } catch (error) {
      setIsLoadingMore(false);
    }
  };

  function handleInfiniteOnLoad(currentGiftData) {
    if (!hasMore) {
      return;
    }
    setIsLoadingMore(true);
    setNextPageNumber(nextPageNumber + 1);
    nextPageNumberRef.current = nextPageNumber + 1;
    pollLeaderboardData(async () => getPaginatedContributersData(currentGiftData, nextPageNumber + 1), initialDelay);
  }

  return (
    <ThemeXModal
      modalTitle={<h2 className="arc-H200 mb0">Gifts from ${item.label}</h2>}
      visible
      wrapClassName="gift-contributors-modal"
      handleCancel={closeModal}>
      <>
        {isLoading && <GiftModalLoadingCard />}
        {!isLoading && (
          <>
            <div className="gift-contributor-header arc-d-flex">
              <p className="arc-p arc-color-B55 mr24">
                <FontAwesomeIcon icon={faGift} className="mr8" role="img" aria-label="Donars" />
                {giftData?.count_gifts}
              </p>
              <If condition={leaderboard?.gift_visibility === TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT}>
                <p className="arc-p arc-color-B55">
                  <FontAwesomeIcon
                    icon={faSeedling}
                    className="mr8 arc-color-primary"
                    role="img"
                    aria-label="Total amount collected"
                  />
                  {formatter(settings.page.currency_display).format(giftData?.total_amount)}
                </p>
              </If>
            </div>
            <div className="gift-contributors">
              <InfiniteScroll
                initialLoad={false}
                loadMore={() => handleInfiniteOnLoad(giftData)}
                hasMore={!isLoadingMore && hasMore}
                useWindow={false}
                threshold={5}>
                <List
                  dataSource={giftData?.results || []}
                  loading={isLoadingMore}
                  renderItem={(d) => (
                    <div className="donor-row">
                      <p className="arc-H150">{d.donor_name}</p>
                      <Choose>
                        <When
                          condition={
                            d.amount && leaderboard?.gift_visibility === TYPE_GIFT_VISIBILITY_SHOW_GIFT_WITH_AMOUNT
                          }>
                          <p className="arc-H150">
                            {commaSeparatedAmountAndCurrency([{ total: d.amount, currency: d.currency }])}
                          </p>
                        </When>
                        <Otherwise>
                          <p className="arc-H150" />
                        </Otherwise>
                      </Choose>
                    </div>
                  )}
                />
              </InfiniteScroll>
            </div>
          </>
        )}
      </>
    </ThemeXModal>
  );
}

GiftModal.propTypes = {
  leaderboard: shape().isRequired,
  item: shape().isRequired,
  closeModal: func.isRequired,
};

export default GiftModal;

/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { find } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import FroalaEditorView from 'Src/common/components/froalaEditor/froalaEditorView';
import { handleEnterKeyPress } from 'Src/common/components/controller/buttons/buttonController';
import CampaignGoalBlock from './goal';
import CampaignModal from './modal';
import { fetchHub } from '../../actions/settings';
import { SettingsConsumer } from '../../context/settings';
import './style.scss';

let foundMatchedCampaign = false;

function CampaignHubs({ hubId, CampaignHubData }) {
  const [settings] = SettingsConsumer();

  const [hubData, setHubData] = useState(CampaignHubData);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  const init = async () => {
    try {
      const response = await fetchHub(settings?.page?.id, hubId);
      if (response && response.data) {
        setHubData(response.data);
      }
    } catch (err) {
      setHubData({});
    }
  };

  const getReqCampaignData = () => find(hubData?.givingx_campaigns, ({ id }) => id === selectedCampaignId) || [];

  const checkForSlug = () => {
    const campaignHubSlug = window.location.pathname.split('/')[3];
    if (campaignHubSlug) {
      return find(hubData?.givingx_campaigns, ({ slug }) => slug === campaignHubSlug);
    }
    return false;
  };

  const shouldOpenCampaignModal = () => {
    const matchedCampaign = checkForSlug();
    if (matchedCampaign && !foundMatchedCampaign) {
      foundMatchedCampaign = true;
      const matchedCampaignElm = document.getElementById(matchedCampaign?.slug);
      if (matchedCampaignElm) matchedCampaignElm.scrollIntoView();
      setTimeout(() => {
        setSelectedCampaignId(matchedCampaign?.id);
      }, [500]);
    }
  };

  useEffect(() => {
    if (hubId) init();
    shouldOpenCampaignModal();
  }, []);

  return (
    <div>
      <div className="section-title">{hubData?.name}</div>
      <Row gutter={[24, 24]}>
        {hubData?.givingx_campaigns?.map((campaign) => (
          <Col
            tabIndex={0}
            className="campaign-hub-col-card-wrapper"
            key={campaign?.id}
            xs={24}
            sm={24}
            md={8}
            onKeyDown={(e) => handleEnterKeyPress(e, () => setSelectedCampaignId(campaign?.id))}
            onClick={() => setSelectedCampaignId(campaign?.id)}
            role="button">
            <div className="campaign-hub-card-wrapper" id={campaign?.slug}>
              <div>
                <Choose>
                  <When condition={!campaign?.feature_image}>
                    <div className="campaign-hub-card-img-placeholder">
                      <FontAwesomeIcon icon={faImage} />
                    </div>
                  </When>
                  <Otherwise>
                    <img className="campaign-hub-card-img" alt="" src={campaign?.feature_image} />
                  </Otherwise>
                </Choose>
                <div className="arc-H200 arc-color-primary mt12 mb12">{campaign?.name}</div>
                <div>
                  <FroalaEditorView model={campaign?.description} />
                </div>
              </div>
              <If condition={campaign?.goal_prefix}>
                <div className="campaign-hub-card-goal">
                  <CampaignGoalBlock
                    completionPercentage={campaign?.completion_percentage}
                    goal={campaign?.goal}
                    goalPrefix={campaign?.goal_prefix}
                    currency={settings?.page?.currency}
                    countDonors={campaign?.count_donors}
                    countGifts={campaign?.count_gifts}
                    amount={campaign?.amount}
                  />
                </div>
              </If>
            </div>
          </Col>
        ))}
      </Row>
      <If condition={selectedCampaignId}>
        <CampaignModal
          hubId={hubData?.id}
          campaignData={getReqCampaignData()}
          handleClose={() => setSelectedCampaignId(null)}
        />
      </If>
    </div>
  );
}

export default CampaignHubs;
